/*
 * action types
 */

export const ADD_USER_DATA = 'ADD_USER_DATA';
export const ADD_ORDER_DATA = 'ADD_ORDER_DATA';
export const SET_WEBVIEW = 'SET_WEBVIEW';
export const SET_ORDER_SCREEN = 'SET_ORDER_SCREEN';
export const SET_ORDER_STEP = 'SET_ORDER_STEP';
export const SET_API_TOKEN = 'SET_API_TOKEN';
export const SET_API_REFRESH_TOKEN = 'SET_API_REFRESH_TOKEN';
export const SET_AVAIABLE_PICKUP_DATA = 'SET_AVAIABLE_PICKUP_DATA';
export const SET_AVAIABLE_DELIVERY_DATA = 'SET_AVAIABLE_DELIVERY_DATA';
export const SET_SELECTED_PICKUP_DATA = 'SET_SELECTED_PICKUP_DATA';
export const SET_SELECTED_DELIVERY_DATA = 'SET_SELECTED_DELIVERY_DATA';
export const SET_SELECTED_PICKUP_TIME = 'SET_SELECTED_PICKUP_TIME';
export const SET_SELECTED_DELIVERY_TIME = 'SET_SELECTED_DELIVERY_TIME';
export const SET_PLACE_ORDER_DATA = 'SET_PLACE_ORDER_DATA';
export const SET_HAS_BAG = 'SET_HAS_BAG';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_FORMATTED_ADRESS = 'SET_FORMATTED_ADRESS';
export const CHECK_LAST_SCREEN = 'CHECK_LAST_SCREEN';
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const RESET_STORAGE = 'RESET_STORAGE';
export const SET_GUEST_API_TOKEN = 'SET_GUEST_API_TOKEN';
export const PICKUP_DATA_LOADING = 'PICKUP_DATA_LOADING';
export const DELIVERY_DATA_LOADING = 'DELIVERY_DATA_LOADING';
export const RESET_AVAILABLE_DELIVERY_DATE = 'RESET_AVAILABLE_DELIVERY_DATE';
export const RESET_SELECTED_DELIVERY = 'RESET_SELECTED_DELIVERY';

/*
 * action creators
 */

export function addUserData(data) {
  return { type: ADD_USER_DATA, data };
}
export function addOrderData(data) {
  return { type: ADD_ORDER_DATA, data };
}
export function setWebView(data) {
  return { type: SET_WEBVIEW, data };
}
export function setOrderScreen(data) {
  return { type: SET_ORDER_SCREEN, data };
}
export function setOrderStep(data) {
  return { type: SET_ORDER_STEP, data };
}
export function setApiToken(data) {
  return { type: SET_API_TOKEN, data };
}
export function setApiRefreshToken(data) {
  return { type: SET_API_REFRESH_TOKEN, data };
}
export function setGuestApiToken(data) {
  return { type: SET_GUEST_API_TOKEN, data };
}
export function setAvaiblePickupData(data) {
  return { type: SET_AVAIABLE_PICKUP_DATA, data };
}
export function setAvaibleDeliveryData(data) {
  return { type: SET_AVAIABLE_DELIVERY_DATA, data };
}
export function setSelectedPickupData(data) {
  return { type: SET_SELECTED_PICKUP_DATA, data };
}
export function setSelectedDeliveryData(data) {
  return { type: SET_SELECTED_DELIVERY_DATA, data };
}
export function setSelectedPickupTime(data) {
  return { type: SET_SELECTED_PICKUP_TIME, data };
}
export function setSelectedDeliveryTime(data) {
  return { type: SET_SELECTED_DELIVERY_TIME, data };
}
export function setPlaceOrderData(data) {
  return { type: SET_PLACE_ORDER_DATA, data };
}
export function setHasBag(data) {
  return { type: SET_HAS_BAG, data };
}
export function setUserLocation(data) {
  return { type: SET_USER_LOCATION, data };
}
export function setUserData(data) {
  return { type: SET_USER_DATA, data };
}
export function setFormattedAddress(data) {
  return { type: SET_FORMATTED_ADRESS, data };
}
export function setNotificationData(data) {
  return { type: SET_NOTIFICATION_DATA, data };
}
export function checkLastScreen(data) {
  return { type: CHECK_LAST_SCREEN, data };
}
export function resetStorage() {
  return { type: RESET_STORAGE };
}
export function pickupDataLoading(loading) {
  return { type: PICKUP_DATA_LOADING, loading };
}
export function deliveryDataLoading(loading) {
  return { type: DELIVERY_DATA_LOADING, loading };
}
export function resetAvailableDeliveryDate() {
  return { type: RESET_AVAILABLE_DELIVERY_DATE }
}
export function resetSelectedDelivery() {
  return { type: RESET_SELECTED_DELIVERY }
}
