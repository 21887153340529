import { store } from '../redux/store';
import {
  addUserData,
  setHasBag
} from "../redux/actions";
import Api from "./api/Api";
import { storageGetUserSession, storageSetUserSession } from "../redux/storage";

export function showFromToTime(from = "00:00") {
  const hours = from.split(":")[0];
  return `${from} - ${+hours + 2}:00`;
}

export const dateFormatter = timestamp => {
  const lang = localStorage.getItem("lang");
  const dateConstructor = new Date(timestamp);
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short"
  };
  const formattedData = dateConstructor.toLocaleDateString(lang === 'en' ? 'en-GB' : 'nl-NL' , options);
  return formattedData === "Invalid Date"
    ? "Select date & time"
    : formattedData;
};

export const getFirstDeliveryDate = (days = 0) => {
  const today = new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
  return today.toISOString().substring(0, 10);
};

export const getApiUrl = () => {
  const PRODUCTION = "order.dobbi.com";
  const STAGING = "test.dobbi.com";

  switch (window.location.hostname) {
    case PRODUCTION:
      return process.env.REACT_APP_API_URL;
    case STAGING:
      return process.env.REACT_APP_DEV_API_URL;
    default:
      return process.env.REACT_APP_DEV_API_URL;
  }
};

export const modalHelper = () => {
  const body = document.body;
  body.classList.toggle("fixed-body");
};

export const langHelper = props => {
  if (props.match.params.lng) {
    localStorage.setItem("lang", props.match.params.lng);
  } else {
    localStorage.setItem("lang", "nl");
  }
  return localStorage.getItem("lang");
};

export function checkQuery(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function urlQuery (object) {
  return {
    search: "?" + new URLSearchParams(object).toString()
  }
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
}

export function getMobileAppLink()
{
  var opSystem = getMobileOperatingSystem();

  if ('Android' === opSystem) {
    return 'https://play.google.com/store/apps/details?id=com.dobbi&hl=nl';
  }

  if ('iOS' === opSystem) {
    return 'https://apps.apple.com/us/app/dobbi/id1374800324';
  }

  return null;
}

export function validateEmail(email)
{
  // eslint-disable-next-line
  return !!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export async function userLoadData(success, fail)
{
  Api.getMe().then(({ data }) => {
    data.name = data.fullName;
    store.dispatch(addUserData(data));
    store.dispatch(setHasBag(data.hasBag));

    success(data)
  }).catch(async (err) => {
    console.log(err);
    await userDestroySession();

    fail();
  });
}

// ----- User session (move to another file in future)----- //

export async function userCreateGuestSession() {
  const response = await Api.getGuestToken();
  const { token, refresh:refreshToken } = response.data;

  return await storageSetUserSession({ token, refreshToken, type: 'guest' });
}

export async function userCreateSession(token, refreshToken) {
  return await storageSetUserSession({ token, refreshToken, type: 'user' });
}

export async function userUpdateSession(token, refreshToken) {
  const session = storageGetUserSession();

  return await storageSetUserSession({ token, refreshToken, type: session.type });
}

export async function userDestroySession() {
  return await storageSetUserSession({ token: '', refreshToken: '', type: 'anon' });
}

export function userIs(type) {
  const session = storageGetUserSession();

  return type === session.type;
}
