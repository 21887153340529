import axios from "axios";
import {getApiRoute, getBaseUrl} from "./routes";
import authInterceptor from "../auth.interceptor";
import uuid from "uuid";
import {storageGetUserSession} from "../../redux/storage";
import {loadProgressBar} from "axios-progress-bar";

class Api {
  baseUrl;
  headers = {
    "Content-Type": "application/json"
  };

  constructor() {
    const lang = localStorage.getItem('lang');

    if (lang) {
      this.headers['Accept-Language'] = lang + ';q=0.9';
    }

    this.baseUrl = getBaseUrl();
  }

  getClient = (token = true, headers = {}) => {
    let config = {
      baseURL: this.baseUrl,
      headers: this.getHeaders(headers, token)
    };

    const api = axios.create(config);

    if (token) {
      api.interceptors.response.use(undefined, authInterceptor(api));
    }

    loadProgressBar({}, api);

    return api;
  };

  getHeaders = (headers = {}, token = true) => {
    if (token) {
      headers['Authorization'] = 'Bearer ' + storageGetUserSession().token;
    }

    return { ...this.headers, ...headers}
  };

  // ------------ //

  checkAccountExist = async (email) => {
    return this.getClient(false).post(getApiRoute('USER_EXISTS'), { email: email });
  };

  authorize = async (username, password) => {
    let auth = { username, password };

    return this.getClient(false, {}, auth).post(getApiRoute('AUTH_TOKEN'), { uuid: uuid() }, {auth: auth});
  };

  getMe = async () => {
    return this.getClient().get(getApiRoute('ME'));
  };

  getMyLocations = async() => {
    return this.getClient().get(getApiRoute('ME_LOCATIONS'));
  };

  sendRecoverPassword = async(password, resetToken) => {
    let data = {
      password: password,
      token: resetToken,
      uuid: uuid()
    };

    return this.getClient(false).post(getApiRoute('RECOVER'), data);
  };

  sendRecoverEmail = async(email) => {
    return this.getClient(false).post(getApiRoute('RECOVER_EMAIL'), { email: email });
  };

  checkRecoverToken = async(recoverToken) => {
    return this.getClient(false).post(getApiRoute('RECOVER_CHECK_TOKEN'), { token: recoverToken });
  };

  checkRecoverEmail = async(email) => {
    return this.getClient(false).post(getApiRoute('RECOVER_CHECK_EMAIL'), { email: email });
  };

  getPickupTime = async(location, hasBag) => {
    let query = {
      postalCode: location.postalCode,
      city: location.city,
      thoroughfare: location.thoroughfare,
      subThoroughfare: location.subThoroughfare,
      isNew: !hasBag ? 1 : 0
    };

    return this.getClient().get(getApiRoute('TIME_WINDOW_PICKUP', query));
  };

  getDeliveryTime = async(date, location, hasBag) => {
    let query = {
      postalCode: location.postalCode,
      city: location.city,
      thoroughfare: location.thoroughfare,
      subThoroughfare: location.subThoroughfare,
      isNew: !hasBag ? 1 : 0
    };

    return this.getClient().get(getApiRoute('TIME_WINDOW_DELIVERY', query) + '&pickupDate=' + date);
  };

  refreshApiToken = async(refreshToken) => {
    let data = {
      'uuid': uuid(),
      'refreshToken': refreshToken
    };

    return this.getClient(false).post(getApiRoute('AUTH_REFRESH_TOKEN'), data);
  };

  getGuestToken = async () => {
    let data = {
      uuid: uuid(),
    };

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        "guest@guest.com:rwwjLvUcMqf9wdCQgCWQHkbFyHkDbP4z"
      )}`,
    };

    return this.getClient(false, headers).post(getApiRoute('AUTH_TOKEN'), data)
  };

  inServiceArea = async (code) => {
    return this.getClient(false).get(getApiRoute('SERVICE_AREA_CHECK', { postalCode : code }));
  };

  createServiceAreaRequest = async(data) => {
    return this.getClient(false).post(getApiRoute('SERVICE_AREA_REQUEST'), data);
  };

  placeOrder = async(data) => {
    function generateUUID() {
      let uuid_key = uuid();
      localStorage.setItem("deviceToken", uuid_key);
      return uuid_key
    }

    let headers = {
      'x-dobbi-device-web-id': localStorage.getItem("deviceToken") != null ? localStorage.getItem("deviceToken") : generateUUID()
    };

    return this.getClient(true, headers).post(getApiRoute('ORDERS'), data);
  };
}

export default new Api();