import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { dateFormatter, showFromToTime } from "utils/helpers";

import translation from '../../../translations/MobileSelectButton'
const lang = localStorage.getItem("lang");

const MobileSelectButton = props => {
  const {
    title,
    onClick,
    pickup,
    delivery,
    selectedPickupDate,
    selectedDeliveryDate,
    selectedPickupTime,
    selectedDeliveryTime
  } = props;

  const selectedClassName = classNames({
    "mobile-select-time": true,
    "selected-block": true,
    "selected-pickup": pickup,
    "selected-delivery": delivery
  });

  const NotSelected = () => {
    return (
      <div onClick={onClick} className="mobile-select-time">
        <span className="bold-select-time">{title}</span>
        <span>{translation.selectDateAndTime[lang]}</span>
      </div>
    );
  };

  const SelectedBlock = () => {
    let date, time;

    if (pickup) {
      date = selectedPickupDate.date;
      time = selectedPickupTime;
    } else if (delivery) {
      date = selectedDeliveryDate.date;
      time = selectedDeliveryTime;
    }

    return (
      <div onClick={onClick} className={selectedClassName} id={pickup ? 'step-4-pickup-container' : 'step-4-delivery-container'}>
        <span className="bold-select-time">{title}</span>
        <div className="selected-date-time">
          <span>{dateFormatter(date)}</span>
          <span>{showFromToTime(time)}</span>
        </div>
      </div>
    );
  };

  if (pickup) {
    return !selectedPickupDate.default ? <SelectedBlock /> : <NotSelected />;
  }
  return !selectedDeliveryDate.default ? <SelectedBlock /> : <NotSelected />;
};

const mapStateToProps = ({
  selectedPickupDate,
  selectedDeliveryDate,
  selectedPickupTime,
  selectedDeliveryTime
}) => ({
  selectedPickupDate,
  selectedDeliveryDate,
  selectedPickupTime,
  selectedDeliveryTime
});

export default connect(mapStateToProps)(MobileSelectButton);
