export default {
	fillAddress: {
		'en': 'Is this your first order?',
		'nl': 'Bestel je voor het eerst?'
	},
	fillAddressSub: {
		'en': 'We can pickup and deliver both at home or at the office. Check the availability for your address.',
		'nl': 'Wij kunnen je order thuis of op kantoor ophalen. Check de beschikbaarheid op jouw adres.'
	},
	enterZipcode: {
		'en': 'Zip Code',
		'nl': 'Postcode'
	},
	houseNumber: {
		'en': 'House number',
		'nl': 'Huisnummer'
	},
	addition: {
		'en': 'Addition/floor',
		'nl': 'Toevoeging'
	},
	eMail: {
		'en': 'E-mail',
		'nl': 'E-mail'
	},
	password: {
		'en': 'Login Password',
		'nl': 'Inlog Wachtwoord'
	},
	passwordNotReceived: {
		'en': 'Password not received?',
		'nl': 'Wachtwoord niet ontvangen?'
	},
	next: {
		'en': 'Check availability',
		'nl': 'Check beschikbaarheid'
	},
	existingAccount: {
		'en': 'Did your order before?',
		'nl': 'Heb je al eerder besteld?'
	},
	existingAccountDesc: {
		'en': 'Enter the e-mail address which you used for your previous order to log in.',
		'nl': 'Log dan in met je e-mail adres en wachtwoord waarmee je eerder bestelt hebt.'
	},
	resetPassword: {
		'en': 'Reset password',
		'nl': 'Wachtwoord opvragen'
	},
	sendLogInPassword: {
		'en': 'Log in',
		'nl': 'Inloggen' 
	},
	passwordSent: {
		'en': 'Password sent',
		'nl': 'Inlog wachtwoord verzonden'
	},
	login: {
		'en': 'Log in',
		'nl': 'Inloggen'
	},
	passwordSentDesc(email) {
		return {
			'en': `We’ve send your password to ${email}. Paste your login password below.'`,
			'nl': `We hebben uw inlog wachtwoord verzonden naar ${email}. Vul het wachtwoord hieronder in'`
		}
	},
	bannerText(appText) {
		return {
			'en': `If you experience problems during the orderproces, please let us know on <a href="mailto:info@dobbi.com">info@dobbi.com</a>${appText}.`,
			'nl': `Loopt het bestelproces niet vlekkeloos? Laat het ons weten via <a href="mailto:info@dobbi.com">info@dobbi.com</a>${appText}.`
		}
	},
	bannerTextApp(appLink) {
		return {
			'en': ` or <a target="_blank" href="${appLink}">download the dobbi app</a>`,
			'nl': ` of <a target="_blank" href="${appLink}">download de dobbi app</a>`
		}
	}
}