export default {
    title: {
        'en': 'Overview & Confirmation',
        'nl': 'Overzicht & bevestig',
    },
    dryClean: {
        'en': 'Dry cleaning & Ironed',
        'nl': 'Stomen & Strijken ',
    },
    washAndFold: {
        'en': 'Wash & Fold',
        'nl': 'Wassen & Vouwen'
    },
    shirtService: {
        'en': 'Shirt Service',
        'nl': 'Overhemden Service',
    },
    shoeCleaning: {
        'en': 'Shoe Cleaning',
        'nl': 'Schoenen Reiniging',
    },
    expertOrder: {
        'en': 'Expert Order',
        'nl': 'Expert Order',
    },
    pickUp: {
        'en': 'Pick-up',
        'nl': 'Ophalen'
    },
    delivery: {
        'en': 'Delivery',
        'nl': 'Bezorgmoment'
    },
    note: {
        'en': 'Note for our dry cleaner',
        'nl': 'Bijzonderheden voor onze reinigingsexpert '
    },
    noteSub: {
        'en': 'e.g ketchup stain on white shirt',
        'nl': 'Bijvoorbeeld: ketchupvlek op een wit overhemd'
    },
    promoCode: {
        'en': 'Promo code',
        'nl': 'Kortingscode'
    },
    promoCodeSub: {
        'en': 'Enter code here',
        'nl': 'Voer hier je kortingscode in'
    },
    paymentMethod: {
        'en': 'Payment method',
        'nl': 'Betaalmethode'
    },
    paymentMethodSub: {
        'en': 'With dobbi you can complete your payment after we have inventoried your order. You always receive an overview of all items that we have collected so that you know exactly what the costs are. Our payment options are: iDeal, creditcard & Paypal.',
        'nl': 'Betaal nadat we je order in ontvangst hebben genomen. Nadat we je order hebben geteld en/of gewogen sturen wij een betaallink waarmee je de betaling kan voldoen.'
    },
    contactDetails: {
        'en': 'Contact details',
        'nl': 'Contactgegevens'
    },
    contactDetailsSub: {
        'en': 'Please fill in your contact details in order for us to keep you updated about the status of your order.',
        'nl': 'Vul je contactinformatie in zodat we je op de hoogte kunnen houden over de status van je order.'
    },
    contactDetailsExisting: {
        'en': 'Contact details',
        'nl': 'Contactgegevens'
    },
    contactDetailsExistingSub: {
        'en': 'Via the contact information below we will keep you informed of your order and we will contact you in case we have questions about your order.',
        'nl': 'Via onderstaande contactinformatie houden we je op de hoogte van je order en nemen we contact met je op bij vragen over jouw order.'
    },
    name: {
        'en': 'Name',
        'nl': 'Naam'
    },
    phoneNumber: {
        'en': 'Phone number',
        'nl': 'Telefoonnummer'
    },
    password: {
        'en': 'Create password for your Dobbi account ( app only )',
        'nl': 'Wachtwoord voor je dobbi account'
    },
    keepInformed: {
        'en': 'Yes, please keep me informed about my order',
        'nl': 'Ja, houd mij op de hoogte over de status van mijn order'
    },
    resetPassword: {
        'en': 'Reset password',
        'nl': 'Wachtwoord opvragen'
    }
}