import {
  ADD_USER_DATA,
  ADD_ORDER_DATA,
  SET_WEBVIEW,
  SET_ORDER_SCREEN,
  SET_ORDER_STEP,
  SET_AVAIABLE_PICKUP_DATA,
  SET_AVAIABLE_DELIVERY_DATA,
  SET_SELECTED_PICKUP_DATA,
  SET_SELECTED_DELIVERY_DATA,
  SET_SELECTED_PICKUP_TIME,
  SET_SELECTED_DELIVERY_TIME,
  SET_PLACE_ORDER_DATA,
  SET_HAS_BAG,
  SET_USER_LOCATION,
  SET_FORMATTED_ADRESS,
  CHECK_LAST_SCREEN,
  RESET_STORAGE,
  SET_NOTIFICATION_DATA,
  SET_API_TOKEN,
  SET_GUEST_API_TOKEN,
  SET_API_REFRESH_TOKEN,
  PICKUP_DATA_LOADING,
  DELIVERY_DATA_LOADING,
  RESET_AVAILABLE_DELIVERY_DATE,
  RESET_SELECTED_DELIVERY
} from './actions';

import { getFirstDeliveryDate } from '../utils/helpers';

const initialState = {
  currentOrderScreen: 1,
  currentOrderStep: 1,
  webView: false,
  apiToken: '',
  apiRefreshToken: '',
  guestApiToken: '',
  orderData: {
    dryCleaning: false,
    washFold: false,
  },
  avaliablePickupDate: [
    {
      default: true,
      date: getFirstDeliveryDate(2),
      hours: [],
    },
  ],
  avaliableDeliveryDate: [
    {
      default: true,
      date: getFirstDeliveryDate(3),
      hours: [],
    },
  ],
  selectedPickupDate: {
    default: true,
    date: 'Select date',
  },
  selectedDeliveryDate: {
    default: true,
    date: 'Select date',
  },
  selectedPickupTime: '00:00',
  selectedDeliveryTime: '00:00',
  formattedAddress: '',
  checkedLastScreen: false,
  userLocation: {
    address: '', //PostNL
    city: '', //Google API
    country: '', //Google API
    lat: 0, //Google API
    lon: 0, //Google API
    postalCode: '', //Already exist from first screen
    loaded: false
  },
  userData: {
    fullName: null,
    phone: '',
    language: '',
    name: '',
    email: '',
    plainPassword: '',
  },
  placeOrderData: {
    couponCode: '',
    notes: '',
  },
  hasBag: null,
  notification: {
    status: false,
    message: '',
  },
  pickupDataLoading: false,
  deliveryDataLoading: false
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_DATA:
      return { ...state, userData: action.data };
    case ADD_ORDER_DATA:
      return { ...state, orderData: { ...state.orderData, ...action.data } };
    case SET_WEBVIEW:
      return { ...state, webView: action.data };
    case SET_ORDER_SCREEN:
      return { ...state, currentOrderScreen: action.data };
    case SET_ORDER_STEP:
      return { ...state, currentOrderStep: action.data };
    case SET_AVAIABLE_PICKUP_DATA:
      return { ...state, avaliablePickupDate: action.data };
    case SET_AVAIABLE_DELIVERY_DATA:
      return { ...state, avaliableDeliveryDate: action.data };
    case SET_API_TOKEN:
      return { ...state, apiToken: action.data };
    case SET_API_REFRESH_TOKEN:
      return { ...state, apiRefreshToken: action.data };
    case SET_GUEST_API_TOKEN:
      return { ...state, guestApiToken: action.data };
    case SET_SELECTED_PICKUP_DATA:
      return { ...state, selectedPickupDate: action.data };
    case SET_SELECTED_DELIVERY_DATA:
      return { ...state, selectedDeliveryDate: action.data };
    case SET_SELECTED_PICKUP_TIME:
      return { ...state, selectedPickupTime: action.data };
    case SET_SELECTED_DELIVERY_TIME:
      return { ...state, selectedDeliveryTime: action.data };
    case SET_PLACE_ORDER_DATA:
      return { ...state, placeOrderData: action.data };
    case SET_HAS_BAG:
      return { ...state, hasBag: action.data };
    case SET_USER_LOCATION:
      action.data.loaded = true;
      return { ...state, userLocation: action.data };
    case SET_FORMATTED_ADRESS:
      return { ...state, formattedAddress: action.data };
    case CHECK_LAST_SCREEN:
      return { ...state, checkedLastScreen: action.data };
    case SET_NOTIFICATION_DATA:
      return { ...state, notification: action.data };
    case RESET_STORAGE:
      return { ...initialState, webView: state.webView };
    case DELIVERY_DATA_LOADING:
      return { ...state, deliveryDataLoading: action.loading };
    case PICKUP_DATA_LOADING:
      return { ...state, pickupDataLoading: action.loading };
    case RESET_AVAILABLE_DELIVERY_DATE:
      return { ...state, avaliableDeliveryDate: initialState.avaliableDeliveryDate };
    case RESET_SELECTED_DELIVERY:
      return { ...state, selectedDeliveryTime: initialState.selectedDeliveryTime, selectedDeliveryDate: initialState.selectedDeliveryDate };

    // case SET_USER_SESSION:
    //   return { ...initialState, session: action.data };
    // case DESTROY_USER_SESSION:
    //   return { ...state, session: { type: 'anon', token: '', refreshToken: '' } };
    default:
      return state;
  }
}
