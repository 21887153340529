import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import UserLogin from "./components/auth/UserLogin";
import { Button } from "./../../components/Button";
import { ModalWrongZip } from "./../../modals/ModalWrongZip";
import { ModalThank } from "./../../modals/ModalThank";
import Notification from './components/Notification.jsx';
import HomeBanner from "./components/HomeBanner";
import { urlQuery, userIs } from "../../utils/helpers";

import {
  setUserLocation,
  addUserData,
  setOrderScreen,
  setFormattedAddress,
  setNotificationData,
  setUserData
} from "../../redux/actions";

import translation from '../../translations/FirstOrderScreen'
import { postNL } from "../../utils/_api-helpers";
import Api from "../../utils/api/Api";

const FirstOrderScreen = props => {
  const { notification } = props;

  // const
  return (
    <main className="order-block-body">
      {notification.status && <Notification />}
      <CheckLocationInputs {...props} />
    </main>
  );
};

class CheckLocationInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resolved: false,
      filledFields: false,
      modalWrong: false,
      modalThank: false,
      validZip: false,
      zip: "",
      houseNumber: "",
      houseAddition: "",
      reason: "",
      validEmail: false,
      modalReset: false,
      token: '',
      passwordSent: false,
      validPassword: false,
      email: '',
      password: '',
    };
    this.button = React.createRef();
    this.handleZipCode = this.handleZipCode.bind(this);
  }

  onLogin = () => {
    this.props.setOrderScreen(3);
  };

  closeModal = () => {
    this.setState({
      modalWrong: false,
      modalThank: false
    });
  };

  compareInputs = () =>
    this.setState({
      filledFields: this.state.validZip && this.houseNumber.value
    });

  validateZip = e => {
    e.target.value = e.target.value.replace(/ /g, "");
    this.zipCode.value = this.zipCode.value.toUpperCase();
    const zip = this.zipCode.value;
    const validZip = !!zip.match(/\d{4}/);
    this.setState({ validZip, zip }, () => this.compareInputs());
  };

  async componentDidMount() {
    if (userIs('user')) {
      this.props.setOrderScreen(3);
    } else {
      this.props.setUserData({});
      this.props.history.push(urlQuery({ page: 'address-selection' }));

      try { window.dataLayer.push({ event: 'zip-code' }) } catch (err) { console.log("Error", err) }

      // try {
      //   const tokenList = await getToken();
      //   const { token } = tokenList.data;
      //   // setApiToken(token);
      //   this.setState({ token: token })
      // } catch (e) {
      //   console.log(e);
      // }
    }
  }

  async getAddressInfo(zipcode, houseNumber, addition) {
    const { setUserLocation, setFormattedAddress, userLocation } = this.props;

    let reason = 'post_nl_validation';

    try {
      const postnlResponse      = await postNL(zipcode, houseNumber, addition);
      const serviceAriaResponse = await Api.inServiceArea(zipcode);

      if (postnlResponse.status === 200) {
        const postNlAddress = postnlResponse.data[0];

        let
          address         = postNlAddress.FormattedAddress[0],
          city            = postNlAddress.City,
          postalCode      = this.zipCode.value,
          thoroughfare    = postNlAddress.Street,
          subThoroughfare = postNlAddress.HouseNumber
        ;

        if (addition && postNlAddress.Addition !== addition) {
          throw new Error(reason);
        }

        if (200 !== serviceAriaResponse.status) {
          reason = 'external_service_error';

          throw new Error(reason);
        }

        if (!serviceAriaResponse.data.isFound) {
          reason = 'not_in_service_area';

          throw new Error(reason);
        }

        if (postNlAddress.Addition) {
          subThoroughfare += '-' + postNlAddress.Addition;
        }

        setUserLocation({ ...userLocation, postalCode });

        setFormattedAddress(postNlAddress.FormattedAddress[0] + ' ' + postNlAddress.FormattedAddress[1]);

        const data = { address, city, postalCode, thoroughfare, subThoroughfare };
        setUserLocation(data);

        this.props.setOrderScreen(2);
      }
    } catch (e) {
      this.button.current.enable();
      console.log(e)
      try { window.dataLayer.push({ event: 'out-of-service-area' }) } catch (err) { console.log("Error", err) }

      localStorage.setItem("zipcode", this.zipCode.value);
      localStorage.setItem("houseNumber", this.houseNumber.value);
      localStorage.setItem("houseAddition", this.addition.value);
      localStorage.setItem("reason", reason);

      this.setState({ modalWrong: true, filledFields: "", zip: "", houseNumber: "", houseAddition: "", reason: ""});
      this.handleClearInputs();
      console.log("Error", e);
    }
  }

  handleClearInputs = () => {
    this.zipCode.value = "";
    this.houseNumber.value = "";
    this.addition.value = "";
  };

  handleZipCode = async () => {
    this.button.current.disable();

    const zipcode = this.zipCode.value;
    const houseNumber = this.houseNumber.value;
    const addition = this.addition.value;

    if (this.state.filledFields) {
      this.getAddressInfo(zipcode, houseNumber, addition);
    }
  };

  handleOpenThankModal = () => {
    this.setState({
      modalThank: true,
      modalWrong: false
    });
  };

  render() {
    const lang = localStorage.getItem("lang");
    const className = this.state.filledFields ? "checked" : "";
    const zip = localStorage.getItem("zipcode");
    const houseAddition = localStorage.getItem('houseAddition');
    const houseNumber   = localStorage.getItem('houseNumber');
    const reason        = localStorage.getItem('reason');

    let postcodeBlock;

    if(!this.state.passwordSent) {
      postcodeBlock = <form className="inputs-wrapper" onSubmit={e => {e.preventDefault();}}>
                        <ModalWrongZip
                          isOpen={this.state.modalWrong}
                          lang={lang}
                          closeModal={this.closeModal}
                          zip={zip}
                          houseAddition={houseAddition}
                          houseNumber={houseNumber}
                          reason={reason}
                          handleOpenThankModal={this.handleOpenThankModal}
                        />
                        <ModalThank
                          isOpen={this.state.modalThank}
                          lang={lang}
                          closeModal={this.closeModal}
                        />
                        <div style={{ minHeight: 90 }}> 
                          <h1>{translation.fillAddress[lang]}</h1>
                          <p>{translation.fillAddressSub[lang]}</p>
                        </div>
                        <input
                          id="step-1-address-input-zip"
                          type="text"
                          placeholder={translation.enterZipcode[lang]}
                          ref={ref => (this.zipCode = ref)}
                          onChange={ this.validateZip }
                          required
                        />
                        <input
                          id="step-1-address-input-house-number"
                          type="text"
                          placeholder={translation.houseNumber[lang]}
                          ref={ref => (this.houseNumber = ref)}
                          onChange={this.compareInputs}
                          required
                        />
                        <input
                          id="step-1-address-input-house-number"
                          type="text"
                          placeholder={translation.addition[lang]}
                          ref={ref => (this.addition = ref)}
                        />
                        <Button
                          id="step-1-address-button"
                          color="blue"
                          onClick={ this.handleZipCode }
                          ref={ this.button }
                          className={className}>{ translation.next[lang] }
                        </Button>
                      </form>
    }

    return (
      <div className="first-screen-inputs">
        <div className="inputs-wrapper" >
          <UserLogin onLogin={ this.onLogin } {...this.props}/>
        </div>
        { postcodeBlock }
        <HomeBanner version={ this.state.passwordSent ? 2 : 1 } />
      </div>
    );
  }
}

const mapStateToProps = ({ webView, userLocation, userData, notification }) => ({
  wv: webView,
  userLocation,
  userData,
  notification
});

const mapDispatchToProps = dispatch => {
  return {
    setOrderScreen: data => dispatch(setOrderScreen(data)),
    setFormattedAddress: data => dispatch(setFormattedAddress(data)),
    setUserLocation: data => dispatch(setUserLocation(data)),
    addUserData: data => dispatch(addUserData(data)),
    setNotificationData: data => dispatch(setNotificationData(data)),
    setUserData: data => dispatch(setUserData(data)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FirstOrderScreen);
