import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom"
import { userDestroySession } from "../../utils/helpers";
import { resetStorage } from "../../redux/actions";

export class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.lang = localStorage.getItem("lang");
  }

  componentWillMount = async () => {
    userDestroySession().then(() => { this.props.resetStorage() });
  };

  render = () => {
    return (
      <Redirect
        to={{
          pathname: '/' + this.lang + '/',
          search: "?page=address-select",
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetStorage: () => dispatch(resetStorage()),
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(Logout);