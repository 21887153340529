import React from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { urlQuery } from '../../../utils/helpers'
import { addUserData, setPlaceOrderData, checkLastScreen } from '../../../redux/actions';
import { dateFormatter, showFromToTime } from '../../../utils/helpers';
import { ModalRecoverySent } from '../../../modals/ModalRecoverySent.jsx'

import Notification from '../components/Notification';
import translation from '../../../translations/ThirdStep'

import envelopIcon from '../../../images/icons/envelop.svg'
import mobileIcon from '../../../images/icons/mobile.svg'
import nameIcon from '../../../images/icons/name.svg'


const ThirdStep = props => {
  const lang = localStorage.getItem("lang");
  const {
    selectedPickupTime,
    selectedDeliveryTime,
    selectedPickupDate,
    selectedDeliveryDate,
    placeOrderData,
    setPlaceOrderData,
    notification,
    orderData
  } = props;

  const setUserNote = e => {
    const notes = e.target.value;
    const data = { ...placeOrderData, notes };
    setPlaceOrderData(data);
  };

  const setPromoCode = e => {
    const couponCode = e.target.value;
    const data = { ...placeOrderData, couponCode };
    setPlaceOrderData(data);
  };

  return (
    <div className="third-option-block">
      {notification.status && <Notification />}

      <h2 className="third-block-header">{translation.title[lang]}</h2>
      <OverviewSection>
        <p className="overview-section-title">
          {orderData['DC'] && translation.dryClean[lang]}
        </p>
        <p className="overview-section-title">
          {orderData['WF'] && translation.washAndFold[lang]}
        </p>
        <p className="overview-section-title">
          {orderData['SS'] && translation.shoeCleaning[lang]}
        </p>
        <p className="overview-section-title">
          {orderData['LS'] && translation.shirtService[lang]}
        </p>
        <p className="overview-section-title">
          {orderData['IO'] && translation.expertOrder[lang]}
        </p>
      </OverviewSection>
      <OverviewSection>
        <div className="selected-services">
          <div className="overview-service">
            <span className="service-section-title">{translation.pickUp[lang]}</span>
            <span>{dateFormatter(selectedPickupDate.date)}</span>
            <span>{showFromToTime(selectedPickupTime)}</span>
          </div>
          <div className="overview-service">
            <span className="service-section-title">{translation.delivery[lang]}</span>
            <span>{dateFormatter(selectedDeliveryDate.date)}</span>
            <span>{showFromToTime(selectedDeliveryTime)}</span>
          </div>
        </div>
      </OverviewSection>
      <OverviewSection>
        <p className="overview-section-title">{translation.note[lang]}</p>
        <textarea
          id="step-5-order-textarea-note"
          className="note-textarea"
          placeholder={translation.noteSub[lang]}
          onChange={setUserNote}
          value={placeOrderData.notes ? placeOrderData.notes : ''}
        />
      </OverviewSection>
      <OverviewSection>
        <p className="overview-section-title">{translation.promoCode[lang]}</p>
        <input
          id="step-5-order-input-coupon"
          className="promocode-input"
          type="text"
          onChange={setPromoCode}
          placeholder={translation.promoCodeSub[lang]}
        />
      </OverviewSection>
      <OverviewSection className="payment-section">
        <p className="overview-section-title white">{translation.paymentMethod[lang]}</p>
        <p>{translation.paymentMethodSub[lang]}</p>
      </OverviewSection>
      <ContactDetails {...props} />
    </div>
  );
};

const OverviewSection = props => {
  const { children, className } = props;
  return (
    <section {...props} className={`${className} overview-section`}>
      {children}
    </section>
  );
};

class ContactDetails extends React.Component {
  state = {
    modalRecoverySent: false,
    userEmail: ''
  }

  setUserData = e => {
    const { userData, addUserData } = this.props;
    switch (e.target.name) {
      case 'phone': {
        const phone = e.target.value;
        const data = { ...userData, phone };
        addUserData(data);
        break;
      }
      case 'name': {
        const name = e.target.value;
        const data = { ...userData, name };
        addUserData(data);
        break;
      }
      case 'password': {
        const plainPassword = e.target.value;
        const data = { ...userData, plainPassword };
        addUserData(data);
        break;
      }
      default:
        break;
    }
  };

  handleCheckboxToggle = e => {
    const { checkLastScreen } = this.props;
    checkLastScreen(e.target.checked);
  };


  componentDidMount() {
    this.props.history.push(urlQuery({ page: 'confirm-order' }));
    try { window.dataLayer.push({ event: 'order-overview' }) } catch (err) { console.log("Error", err) }
  }

  render() {
    const lang = localStorage.getItem("lang");

    if(this.props.userData.fullName == null) {
      return (
        <OverviewSection className="contact-section">
            <p className="contact-section-title">{translation.contactDetails[lang]}</p>
            <p>{translation.contactDetailsSub[lang]}</p>
            <div className="contact-section-inputs">
              <input
                id="step-5-user-input-name"
                placeholder={translation.name[lang]}
                name="name"
                type="text"
                value={this.props.userData && this.props.userData.name ? this.props.userData.name : ''}
                onChange={this.setUserData}
              />
              <input
                id="step-5-user-input-phone"
                placeholder={translation.phoneNumber[lang]}
                name="phone"
                onChange={this.setUserData}
                value={this.props.userData && this.props.userData.phone ? this.props.userData.phone : ''}
                type="text"
              />
              <div className="checkbox-input">
                <input
                  id="step-5-user-checkbox-order-updates"
                  type="checkbox"
                  defaultChecked={this.props.checkedLastScreen}
                  onChange={this.handleCheckboxToggle}
                />
                <label htmlFor="step-5-user-checkbox-order-updates">
                  {translation.keepInformed[lang]}
                </label>
              </div>
            </div>
            <ModalRecoverySent
              isOpen={this.state.modalRecoverySent}
              email={this.state.userEmail}
              lang={lang}
              closeModal={this.closeModal}
            />
        </OverviewSection>
      );
    } else {
      return (
        <OverviewSection className="contact-section">
            <p className="contact-section-title">{translation.contactDetailsExisting[lang]}</p>
            <p>{translation.contactDetailsExistingSub[lang]}</p>
            <div className="contact-section-inputs" style={{marginTop: '30px'}}>
              <div className="contact-section-input-data">
                <div className="contact-section-input-data-image">
                  <img src={nameIcon} alt="" />
                </div>
                <div>
                  { this.props.userData.fullName }
                </div>
              </div>
              <div className="contact-section-input-data">
                <div className="contact-section-input-data-image">
                  <img src={mobileIcon} alt="" />
                </div>
                +{ this.props.userData.phone }
              </div>
              <div className="contact-section-input-data">
                <div className="contact-section-input-data-image">
                  <img src={envelopIcon} alt="" />
                </div>
                { this.props.userData.email }
              </div>
            </div>
        </OverviewSection>
      )
    }
  }
}

const mapStateToProps = ({
  selectedPickupTime,
  selectedDeliveryTime,
  selectedPickupDate,
  selectedDeliveryDate,
  placeOrderData,
  userData,
  orderData,
  apiToken,
  notification,
  checkedLastScreen
}) => ({
  selectedPickupTime,
  selectedDeliveryTime,
  selectedPickupDate: selectedPickupDate,
  selectedDeliveryDate: selectedDeliveryDate,
  placeOrderData,
  userData,
  orderData,
  token: apiToken,
  notification,
  checkedLastScreen
});

const mapDispatchToProps = dispatch => {
  return {
    addUserData: data => dispatch(addUserData(data)),
    checkLastScreen: data => dispatch(checkLastScreen(data)),
    setPlaceOrderData: data => dispatch(setPlaceOrderData(data)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ThirdStep);
