import React from "react";

import whiteArrow from "images/order/white-arrow.svg";
import blueArrow from "images/order/blue-arrow.svg";
import greenArrow from "images/order/green-arrow.svg";

import "css/pages/Order/components/ButtonWithArrow.css";

export const ButtonWithArrow = props => {
  const src =
    props.className && props.className.includes("checked")
      ? whiteArrow
      : blueArrow;
  return (
    <button {...props} className={`button_with-arrow ${props.className}`}>
      <div>{props.children}</div>
      <img src={src} alt="" />
    </button>
  );
};

export const OrderBackButton = props => {
  return (
    <button
      {...props}
      className={`button_with-arrow back-button ${props.className}`}
    >
      {props.children}
      <img src={greenArrow} alt="" />
    </button>
  );
};
