import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loader from 'react-loader-spinner'
import AuthWrapper from "./components/AuthWrapper";
import AuthHeader from "./components/AuthHeader";
import translation from '../../translations/Auth'
import HomeBanner from "../Order/components/HomeBanner";
import { setNotificationData } from "../../redux/actions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "css/components/auth/Login.css";
import RecoverExpired from "./components/RecoverExpired";
import RecoverPassword from "./components/RecoverPassword";
import "../../css/pages/user/Recover.css";
import Notification from "../Order/components/Notification";
import Api from "../../utils/api/Api";

export class RecoverReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 'checking',
      ttl: 7200
    };

    this.lang = localStorage.getItem("lang")
  }

  async componentWillMount () {
    Api.checkRecoverToken(this.props.match.params.token)
      .then(({ data }) => {
        this.setState({
          state: 'password'
        });
      })
      .catch((err) => {
        let error = err.response.data;
        let type = error.type;

        if ('user_recover_token_expired' === type) {
          this.setState({
            state: 'expired',
            ttl: error.extra.ttl
          });
        } else {
          this.props.history.push('/' + this.lang + '/404');
        }
      })
    ;
  }

  render() {
    let content;
    let className = 'inputs-wrapper';
    const { notification } = this.props;

    if ('expired' === this.state.state) {
      content = <RecoverExpired ttl={ this.state.ttl }/>;
      className += ' show-expired-background';
    }

    if ('password' === this.state.state) {
      content = <RecoverPassword token={ this.props.match.params.token }/>;
    }

    if ('checking' === this.state.state) {
      content =
        <Loader
          className="page-loader"
          type="Grid"
          color="#5bffc6"
          height={100}
          width={100}
          timeout={30000}
        />
      ;
    }

    return (
      <AuthWrapper {...this.props}>
        { notification && notification.status && <Notification /> }
        <AuthHeader title={translation.title[this.lang]}/>
        <main className="order-block-body">
          <div className="first-screen-inputs recover">
            <div className={ className }>
              <div style={{width: '80%'}}>
                  { content }
              </div>
            </div>
            <HomeBanner version={1}/>
          </div>
        </main>
      </AuthWrapper>
    )
  }
}

const mapStateToProps = ({ notification, webView }) => ({
  notification,
  webView
});

const mapDispatchToProps = dispatch => {
  return {
    setNotificationData: data => dispatch(setNotificationData(data)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RecoverReset);
