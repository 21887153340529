import React from "react";
import {compose} from "redux";

import "css/components/Countdown.css";
import {Time} from "./Time";

export class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.seconds = undefined === props.seconds ? 0 : props.seconds;

    this.state = { seconds: this.seconds };
    this.timer = 0;
    this.lang = localStorage.getItem("lang");
    this.numerous = require('numerous');
    this.time = React.createRef();
  }

  startTimer = () => {
    if (0 === this.timer && 0 < this.state.seconds) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
    });

    if (this.time.current) {
      this.time.current.setSeconds(seconds);
    }

    // Check if we're at zero.
    if (0 === seconds) {
      clearInterval(this.timer);
      this.onFinish();
    }
  };

  onFinish = () => {
    if (typeof this.props.onFinish === 'function') {
      this.props.onFinish();
    }
  };

  render() {
    return(
      <Time className="cd-wrapper" seconds={ this.state.seconds } ref={ this.time }/>
    );
  }
}

export default compose() (Countdown);