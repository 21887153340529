import React from "react";
import {getMobileAppLink} from "../../../utils/helpers";

import "css/pages/Order/components/HomeBanner.css";

import translation from '../../../translations/FirstOrderScreen';

import image1 from '../../../images/home/banner-left.png';
import image2 from '../../../images/map-square.png';

export default class HomeBanner extends React.Component {
  static defaultProps = {
    version: 1
  };

  getImageSrc = () => {
    return 1 === this.props.version ? image1 : image2;
  };

  renderV1 = () => {
    return (
      <div className="home-banner inputs-wrapper">
        <img src={this.getImageSrc()} alt="" className="map-block" />
      </div>
    );
  };

  renderV2 = () => {
    const
      lang    = localStorage.getItem("lang"),
      appLink = getMobileAppLink(),
      appText = null == appLink ? '' : translation.bannerTextApp(appLink)[lang]
    ;

    return(
      <div className="home-banner inputs-wrapper">
        <p dangerouslySetInnerHTML={{__html: translation.bannerText(appText)[lang]}} />
        <img src={this.getImageSrc()} width="320px" height="320px" alt="" className="map-block" />
      </div>
    );
  };

  updateHtmlClass = () => {
    const documentRoot = window.document.querySelector('html');

    documentRoot.classList.remove('home-banner-version-1');
    documentRoot.classList.remove('home-banner-version-2');

    window.document.querySelector('html').classList.add('home-banner-version-' + this.props.version);
  };

  render() {
    this.updateHtmlClass();

    if (2 === this.props.version) {
      return this.renderV1();
    }

    return this.renderV2();
  }
}