import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import { AccordionBlockFirst, AccordionBlockSecond } from "./AccordionJobs";
import { translation } from "../utils/dataFiles/jobs-loco";
import "react-accessible-accordion/dist/fancy-example.css";
import "../css/components/MobileTabs.css";

const { tab_01, tab_02 } = translation;

export const MobileTabs = props => {
  const lang = localStorage.getItem("lang");
  return (
    <Accordion className="faq-mobile-tabs__block accodrion-block">
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{tab_01[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AccordionBlockFirst />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <div className="acc-title-wrapper">
            <h3 className="u-position-relative">{tab_02[lang]}</h3>
            <div className="accordion__arrow" role="presentation" />
          </div>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <AccordionBlockSecond />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
