export default {
    title: {
        en: `Welcome to dobbi order online.`,
        nl: `Welkom bij dobbi online bestellen.`
    },
    findAddress: {
        en: 'Fill in your address',
        nl: 'Voer hier je adres in'
    },
    location: {
        en: 'Your pickup & delivery location',
        nl: 'Ophaal- & bezorgadres'
    },
    edit: {
        en: 'Edit',
        nl: 'Wijzig'
    },
    editAddress: {
        en: 'Edit address',
        nl: 'Wijzig adres'
    },
    betaText : {
        'en': 'Dobbi order online',
        'nl': 'Dobbi online bestellen'
    },
    beta : {
        'en': 'Beta',
        'nl': 'Beta'
    }
}