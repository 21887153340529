import pathToRegexp from 'path-to-regexp';

export const ROUTE_NAME = {
  AUTH_TOKEN: 'auth.token',
  AUTH_REFRESH_TOKEN: 'auth.refresh.token',

  RECOVER_ACCOUNT_EXISTS: 'recover.account.exists',
};

// Register routes
export const apiRoutes = {
  AUTH_TOKEN: '/tokens',
  AUTH_REFRESH_TOKEN: '/tokens/refresh',
  USER_EXISTS: '/users/exists',
  ME: '/me',
  ME_LOCATIONS: '/me/locations',
  RECOVER: '/recover',
  RECOVER_EMAIL: '/recover/email',
  RECOVER_CHECK_TOKEN: '/recover/check/token',
  RECOVER_CHECK_EMAIL: '/recover/check/email',
  TIME_WINDOW_PICKUP: '/times/pickups?postalCode=:postalCode&city=:city&thoroughfare=:thoroughfare&subThoroughfare=:subThoroughfare&isNew=:isNew',
  TIME_WINDOW_DELIVERY: '/times/deliveries?postalCode=:postalCode&city=:city&thoroughfare=:thoroughfare&subThoroughfare=:subThoroughfare&isNew=:isNew',
  SERVICE_AREA_CHECK: '/service-areas/?postalCode=:postalCode',
  SERVICE_AREA_REQUEST: '/service-areas/request',
  ORDERS: '/orders'
};

export const getBaseUrl = () => {
  const PRODUCTION = "order.dobbi.com";
  const STAGING = "test.dobbi.com";

  switch (window.location.hostname) {
    case PRODUCTION:
      return process.env.REACT_APP_API_URL;
    case STAGING:
      return process.env.REACT_APP_DEV_API_URL;
    default:
      return process.env.REACT_APP_DEV_API_URL;
  }
};

export const getApiRoute = (name, params = {}) => pathToRegexp.compile(apiRoutes[name])(params);