import React from 'react';
import ios from '../images/itunes.svg';
import google from '../images/google-play.png';
import '../css/components/AppLinks.css';
import { getMobileOperatingSystem } from "../utils/helpers";

const AppLinks = props => {
  const userAgent = getMobileOperatingSystem() === "Android" ? 'android' : getMobileOperatingSystem() === "iOS" ? 'ios' : null;

  if (userAgent === 'ios') {
    return (
      <div className="desktop_header-link app-links">
        <a href="https://itunes.apple.com/us/app/dobbi/id1374800324">
          <img src={ios} alt="" className="itunes-icon" width="134" height="40" />
        </a>
      </div>
    )
  } else if (userAgent === 'android') {
    return (
      <div className="desktop_header-link app-links">
        <a href="https://play.google.com/store/apps/details?id=com.dobbi">
          <img src={google} alt="" className="google-play-icon" width="134" height="41" />
        </a>
      </div>
    )
  }
  return (
    <div className="desktop_header-link app-links">
      <a href="https://itunes.apple.com/us/app/dobbi/id1374800324">
        <img src={ios} alt="" className="itunes-icon" width="134" height="40" />
      </a>

      <a href="https://play.google.com/store/apps/details?id=com.dobbi">
        <img src={google} alt="" className="google-play-icon" width="134" height="41" />
      </a>
    </div>
  );
};

export default AppLinks;
