import React from "react";

import AppLinks from "./AppLinks";

// import detectmob from "../utils/userAgent.js";
import appPromo from "../images/home/app-promo.png";
import appPromo2x from "../images/home/app-promo@2x.png";
import appPromoNl from "../images/home/app-promo-nl.png";
import appPromo2xNl from "../images/home/app-promo-nl@2x.png";
import appBubbles from "../images/app-bubbles.svg";

import "../css/components/DownloadApp.css";

const translation = {
  download_01: {
    en: "Download your app",
    nl: "Download jouw app"
  },
  download_02: {
    en: "We know there’s nothing wrong with doing your laundry the way it is,",
    nl: "Of het nu gaat om wassen of stomen, wij halen je kleding op waar en wanneer het jou uitkomt."
  },
  download_03: {
    en: "But in just a few swipes, you’ll start experiencing a life filled with small wonders.",
    nl: "Ervaar nu het gemak en professionele kwaliteit aan huis."
  },
}
const { download_01, download_02, download_03 } = translation;

const DownloadApp = props => {
  const lang = localStorage.getItem("lang");
  return (
    <section className="download-app" id="download-app">
      <div className="download-text">
        <h1>{download_01[lang]}</h1>
        <p>
          {download_02[lang]}<br />
          {download_03[lang]}
        </p>
        <AppLinks />
      </div>
      <div>
        <div className="app_img-wrapper">
          {lang === 'nl'
            ? <img className="app-promo-img" src={appPromoNl} srcSet={`${appPromoNl} 1x, ${appPromo2xNl} 2x`} alt="" />
            : <img className="app-promo-img" src={appPromo} srcSet={`${appPromo} 1x, ${appPromo2x} 2x`} alt="" />}
          <img className="app-bubbles" src={appBubbles} alt="" />
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
