import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import uuid from "uuid";
import { connect } from "react-redux";
import 'axios-progress-bar/dist/nprogress.css'

import { cookiePopupCheck, CoockieModal } from "./modals/CoockieModal";
import {
  checkQuery,
  userDestroySession,
  userIs,
  userLoadData
} from "./utils/helpers";
import { addUserData, setHasBag, setWebView } from "redux/actions";
import ScrollToTop from "./utils/ScrollToTop";
import AppSmartBanner from "components/AppSmartBanner";

import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import Pricelist from "./pages/Pricelist";
import Explore from "./pages/Explore";
import Faq from "./pages/Faq";
import Terms from "./pages/Terms";
import Cookie from "./pages/Cookie";
import Order from "./pages/Order";
import Jobs from "./pages/Jobs";

import Welcome from "./pages/stripped_pages/Welcome";
import Thankyou from "./pages/stripped_pages/Thankyou";
import ValidFail from "./pages/stripped_pages/Valid-fail";
import PayFail from "./pages/stripped_pages/Pay-fail";

import smartBannerTranslation from './translations/AppSmartBanner'
import Recover from "./pages/user/Recover";
import { RecoverReset } from "./pages/user/RecoverReset";
import {resetStorage, setApiToken} from "./redux/actions";
import Logout from "./components/auth/Logout";

const redirectRoutes = [
  {
    path: "/",
    exact: true
  },
  {
    path: "/contact",
    exact: false
  },
  {
    path: "/pricelist",
    exact: false
  },
  {
    path: "/explore",
    exact: false
  },
  {
    path: "/faq",
    exact: false
  },
  {
    path: "/terms",
    exact: false
  },
  {
    path: "/cookie",
    exact: false
  },

  {
    path: "/order",
    exact: false
  },
  {
    path: "/recover",
    exact: false
  }
];

const rootRoutes = [
  {
    path: "/:lng(nl|en)?",
    exact: true,
    component: Order
  },
  {
    path: "/:lng(nl|en)?/recover",
    exact: true,
    component: Recover
  },
  {
    path: "/:lng(nl|en)?/recover/:token",
    exact: true,
    component: RecoverReset
  },
  {
    path: "/:lng(nl|en)?/logout",
    exact: true,
    component: Logout
  },
  {
    path: "/:lng(nl|en)?/contact",
    exact: false,
    component: Contact
  },
  {
    path: "/:lng(nl|en)?/pricelist",
    exact: false,
    component: Pricelist
  },
  {
    path: "/:lng(nl|en)?/explore",
    exact: false,
    component: Explore
  },
  {
    path: "/:lng(nl|en)?/faq",
    exact: false,
    component: Faq
  },
  {
    path: "/:lng(nl|en)?/terms",
    exact: false,
    component: Terms
  },
  {
    path: "/:lng(nl|en)?/cookie",
    exact: false,
    component: Cookie
  },
  {
    path: "/:lng(nl|en)?/order",
    exact: false,
    component: Order
  },
  {
    path: "/:lng(nl|en)?/jobs",
    exact: false,
    component: Jobs
  },
  {
    path: "/:lng(nl|en)?/welcome",
    exact: false,
    component: Welcome
  },
  {
    path: "/:lng(nl|en)?/thank",
    exact: false,
    component: Thankyou
  },
  {
    path: "/:lng(nl|en)?/valid-fail",
    exact: false,
    component: ValidFail
  },
  {
    path: "/:lng(nl|en)?/pay-fail",
    exact: false,
    component: PayFail
  },
  {
    path: "/:lng(nl|en)?/404",
    exact: false,
    component: Error404
  },
  {
    path: "*",
    exact: false,
    component: Error404
  }
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      webview: false,
      cookiesSeen: false,
      loading: true
    };
  }

  componentWillMount = async() => {
    localStorage.removeItem("lang");
    if (!localStorage.getItem("lang")) {
      if (window.location.pathname.includes("/en")) {
        localStorage.setItem("lang", "en");
      } else {
        localStorage.setItem("lang", "nl");
      }
    }

    !cookiePopupCheck() &&
      setTimeout(() => {
        this.setState({ cookiesSeen: true });
      }, 3000);

    //Check does URL component has webView GET param, in this condition set state
    this.props.setWebView(!checkQuery("webview"));

    // Google Analytics
    const s = document.createElement('script');
    const gtmKey = 'GTM-T7RRKZX';
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gtmKey}');`;
    document.body.appendChild(s);

    if (userIs('guest')) {
      userDestroySession().then(() => {
        this.props.resetStorage();
        this.loaded();
      });
    } else if (userIs('user')) {
      userLoadData(
        () => {
          this.loaded()
        },
        () => {
          this.loaded()
        }
      );
    } else {
      this.loaded();
    }
  };

  loaded = () => {
    this.setState({
      loading: false
    });
  };

  render() {
    const lang = localStorage.getItem("lang");

    if (this.state.loading) {
      return null;
    }

    const appRedirects = redirectRoutes.map(route => (
      <Redirect
        exact={route.exact}
        from={route.path}
        to={`/nl${route.path}`}
        key={uuid()}
      />
    ));

    const appRoutes = rootRoutes.map(route => (
      <Route
        path={route.path}
        component={route.component}
        exact={route.exact}
        key={uuid()}
      />
    ));

    return (
      <Router>
        <ScrollToTop>
          {this.state.cookiesSeen && !this.props.webview && <CoockieModal />}
          <AppSmartBanner
            title={smartBannerTranslation.title[lang]}
            storeText={{
              'ios': smartBannerTranslation.text[lang],
              'android': smartBannerTranslation.text[lang]
            }}
            price={null}
            button={smartBannerTranslation.button[lang]}
            appStoreLanguage={lang}
            ignoreIosVersion={true}
          />
          <Switch>
            {appRedirects}
            {appRoutes}
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = ({ webview }) => ({ webview });

const mapDispatchToProps = dispatch => {
  return {
    setWebView: data => dispatch(setWebView(data)),
    addUserData: data => dispatch(addUserData(data)),
    setApiToken: data => dispatch(setApiToken(data)),
    setHasBag: data => dispatch(setHasBag(data)),
    resetStorage: () => dispatch(resetStorage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
