import React from "react";
import { Logo } from "./Logo";
import NavLinks from "./NavLinks";
import HamButton from "./HamButton";
import AppLinks from "./AppLinks";

import enFlag from '../images/en-flag.svg'
import nlFlag from '../images/nl-flag.svg'
import phone from '../images/home/banner-phone.png'
import phone2x from '../images/home/banner-phone@2x.png'
import phoneNl from '../images/home/banner-phone-nl.png'
import phone2xNl from '../images/home/banner-phone-nl@2x.png'
import sides from '../images/home/mobile-sides.png'

import accountImg from "../images/account.svg";

import "../css/components/Header.css";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {userIs} from "../utils/helpers";

const translation = {
  title1: {
    en: "DOWNLOAD THE APP",
    nl: "Download de app"
  },
  title2: {
    en: "to skip your laundry",
    nl: " en skip je was"
  },
  title3: {
    en: "& DRY CLEANing",
    nl: "& stomerij"
  },
  descr: {
    en: "We pick up, clean and deliver within 48 hours. Download our app and experience the dobbi magic now!",
    nl: "Je wasgoed of stoomgoed opgehaald, gereinigd en teruggebracht binnen 48 uur. Ervaar de magie van dobbi!"
  },
  headerDownload: {
    en: "Get the app",
    nl: "Download app"
  },
  logoutLink: {
    en: 'Log out',
    nl: 'Uitloggen'
  }
};

const { headerDownload, title1, title2, title3, descr } = translation;

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      valid: false
    };

    this.lang = localStorage.getItem("lang")
  }

  render() {

    let links;
    let mobileLinks;

    if (userIs('user')) {
      links = <div>
        <img src={ accountImg } alt="" width="35"/>
        <Link to={ '/' + this.lang + '/logout' }>{ translation.logoutLink[this.lang] }</Link>
      </div>;

      mobileLinks = <div>
        <Link to={ '/' + this.lang + '/logout' }>{ translation.logoutLink[this.lang] }</Link>
      </div>;
    }

    return (
      <HeroImage img={this.props.heroImg} img2x={this.props.heroImg2x} whiteHeader={this.props.whiteHeader}>
        <LangSwitcher header wv={this.props.wv}/>
        {this.props.wv && <div className="hero-inner">
          <Logo wv={this.props.wv} {...this.props} />
          <TopMenu wv={this.props.wv}>{ links }</TopMenu>
          <HamButton/>
        </div>}
        <MobileMenu wv={this.props.wv}>
          { mobileLinks }
        </MobileMenu>
        {this.props.children}
      </HeroImage>
    );
  }
}

const HeroImage = props => {
  const className = props.whiteHeader ? 'hero-img-wrapper error-page ' : 'hero-img-wrapper';
  const lang = localStorage.getItem("lang");
  return (
    <header className="hero-image">
      {props.children}
      <div className={className}>
        <img src={sides} alt="" className="mobile-sides" />
        <div className="header-text">
          <div className="header-text-descr">
            <p className="white-titles">{title1[lang]}</p>
            <p className="white-titles">{title2[lang]}</p>
            <p className="white-titles">{title3[lang]}</p>
            <p className="white-descr">{descr[lang]}</p>
          </div>
          <div className="header-text-phone">
            {lang === 'nl'
              ? <img src={phoneNl} srcSet={`${phoneNl} 1x, ${phone2xNl} 2x`} alt="" width="100%" />
              : <img src={phone} srcSet={`${phone} 1x, ${phone2x} 2x`} alt="" width="100%" />}
          </div>
        </div>
      </div>
      <div className="shadow-container" />
    </header >
  );
};

const MobileMenu = props => {
  const lang = localStorage.getItem("lang");

  return props.wv ? (
    <section className="mobile_menu">
      <NavLinks>
        { props.children }
      </NavLinks>
      <div className="mobile_applink">
        <p>{headerDownload[lang]}</p>
        <AppLinks />
      </div>
    </section>
  ) : '';
};

const TopMenu = props => {
  return (
    <section className="top-menu">
      <NavLinks>{ props.children }</NavLinks>
      <LangSwitcher wv={props.wv} />
    </section>
  );
};

export const languageSwitch = (event, lng) => {
  event && event.preventDefault();
  let currentUrl;

  if (localStorage.getItem('lang') === lng) return
  currentUrl = window.location.pathname.replace(new RegExp('/en|/nl', 'g'), '')

  let baseUrl = window.location.origin;
  localStorage.setItem('lang', lng)
  window.location.replace(baseUrl + '/' + lng + currentUrl);
}

export const LangSwitcher = props => {
  let activeLang = localStorage.getItem('lang') === 'en';
  const wrapperClassName = props.header ? "switcher-header" : "switcher-menu"
  return props.wv ?
    <div className={`switcher-wrapper ${wrapperClassName}`}>
      {props.header && <span className="language-span">Language</span>}
      <div className="lng-switcher">
        <div className={`lang-item ${activeLang && "active"} `} onClick={(event) => { languageSwitch(event, 'en') }}>
          <img src={enFlag} alt="" />
          <span>EN</span>
        </div>
        <div className={`lang-item ${!activeLang && "active"}`} onClick={(event) => { languageSwitch(event, 'nl') }}>
          <img src={nlFlag} alt="" />
          <span>NL</span>
        </div>
      </div>
    </div>
    : <div />
};

const mapStateToProps = ({ userData }) => ({
  userData
});

export default compose(
  connect(
    mapStateToProps
  )
)(Header);
