import React from 'react';
import equal from 'fast-deep-equal';
import classNames from 'classnames';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { dateFormatter } from 'utils/helpers';
import {
  setSelectedPickupData,
  setSelectedDeliveryData,
  setSelectedPickupTime,
  setSelectedDeliveryTime,
  setAvaibleDeliveryData,
  deliveryDataLoading
} from '../../../redux/actions';

import translation from '../../../translations/SelectDateModal'
import Api from "../../../utils/api/Api";
const lang = localStorage.getItem("lang");

class SelectDateModal extends React.PureComponent {
  state = { isOpened: false };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    const { handleToggleModal } = this.props;
    if (
      this.state.isOpened &&
      this.container &&
      !this.container.contains(e.target)
    ) {
      handleToggleModal();
    }
  };

  componentDidUpdate(prevProps) {
    if (!equal(this.props.isOpened, prevProps.isOpened)) {
      this.setState({ isOpened: this.props.isOpened });
    }
  }

  handleSelectTime = async (e, date) => {
    const {
      setAvaibleDeliveryData,
      setSelectedPickupData,
      setSelectedDeliveryData,
      setSelectedPickupTime,
      setSelectedDeliveryTime,
      handleToggleModal,
      pickup,
      userLocation,
      hasBag,
      deliveryDataLoading
    } = this.props;
    const target = e.target.closest('.hour-block');
    const targetFrom = target.getAttribute('data-from');

    if (pickup) {
      setSelectedPickupData({ date });
      setSelectedPickupTime(targetFrom);
      setSelectedDeliveryData({ default: true });
    } else {
      setSelectedDeliveryData({ date });
      setSelectedDeliveryTime(targetFrom);
    }

    const dateForApi = encodeURIComponent(`${date}T${targetFrom}:00`);

    if (pickup) {
      deliveryDataLoading(true)
    }

    pickup &&
      Api.getDeliveryTime(dateForApi, userLocation, hasBag)
        .then(response => response.data)
        .then((delivery) => {
          setAvaibleDeliveryData(delivery);
          deliveryDataLoading(false);
        })
        .then(() => setSelectedPickupTime(targetFrom))
        .catch(error => console.log(error));

    handleToggleModal();
  };

  render() {
    const { isOpened } = this.state;

    const {
      handleToggleModal,
      pickup,
      avaliablePickupDate,
      avaliableDeliveryDate,
    } = this.props;

    const modalTitle = translation.selectDateAndTime[lang];

    const modalData = pickup ? avaliablePickupDate : avaliableDeliveryDate;
    return isOpened ? (
      <div
        id={pickup ? 'window-step-4-select-pickup-time-windows' : 'window-step-4-select-delivery-time-windows'}
        className="select-date-modal-wrapper"
      >
        <div
          className="select-date-modal-content"
          ref={ref => (this.container = ref)}
        >
          <div className="select-date-header">
            <span>{modalTitle}</span>
            <div
              className="close-button select-date-close-button"
              onClick={handleToggleModal}
            >
              &#10005;
            </div>
          </div>
          <div className="select-date-body">
            {modalData.map(item => {
              return (
                <ModalDateBlock
                  date={item.date}
                  hours={item.hours}
                  onItemClick={this.handleSelectTime}
                  key={uuid()}
                />
              );
            })}
          </div>
        </div>
      </div>
    ) : null;
  }
}

const ModalDateBlock = props => {
  const { date, hours, onItemClick } = props;
  return (
    <div className="modal-date-block">
      <p>{dateFormatter(date)}</p>
      <div className="modal-hours-wrapper">
        {hours &&
          hours.map(hour => {
            const { available } = hour;
            const className = classNames({
              'hour-block': true,
              available,
            });
            return (
              <div
                className={className}
                key={uuid()}
                onClick={e => hour.available && onItemClick(e, date)}
                data-from={hour.from}
              >
                <span>{hour.from}</span>
                <span>{hour.to}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  avaliablePickupDate,
  avaliableDeliveryDate,
  selectedPickupDate,
  selectedDeliveryDate,
  apiToken,
  userLocation,
  hasBag
}) => ({
  token: apiToken,
  selectedPickupDate,
  selectedDeliveryDate,
  avaliablePickupDate,
  avaliableDeliveryDate,
  userLocation,
  hasBag
});

const mapDispatchToProps = dispatch => ({
  setAvaibleDeliveryData: data => dispatch(setAvaibleDeliveryData(data)),
  setSelectedPickupData: data => dispatch(setSelectedPickupData(data)),
  setSelectedDeliveryData: data => dispatch(setSelectedDeliveryData(data)),
  setSelectedPickupTime: data => dispatch(setSelectedPickupTime(data)),
  setSelectedDeliveryTime: data => dispatch(setSelectedDeliveryTime(data)),
  deliveryDataLoading: data => dispatch(deliveryDataLoading(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDateModal);
