import React from 'react';
import { connect } from 'react-redux';
import { setNotificationData } from 'redux/actions';

class Notification extends React.Component {
  handleScrollSticky = () => {
    const notify = document.querySelector('.notification-wrapper');
    const mainToTop = document.querySelector('main').getBoundingClientRect()
      .top;
    if (notify) {
      mainToTop < 0
        ? notify.classList.add('sticky')
        : notify.classList.remove('sticky');
    }
  };

  componentDidMount() {
    let selector = document.querySelector('.notification-wrapper');
    setTimeout(() => {
      selector.classList.add('active');
    }, 1);

    //Check position after loading
    this.handleScrollSticky();
    //And check it every time, after user scroll
    window.onscroll = this.handleScrollSticky;
  }

  closeModal = () => {
    const { setNotificationData } = this.props;
    const data = { status: false, message: '' };
    setNotificationData(data);
  };

  render() {
    const { type, text } = this.props;
    return (
      <div className={`notification-wrapper ${type}`}>
        <span className="color-margin" />
        <span
          className="notification-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <button className="notification-close" onClick={this.closeModal}>
          &#10005;
        </button>
      </div>
    );
  }
}

Notification.defaultProps = {
  type: 'notice',
  text: 'Test test test test blah blah blah bla',
};

const mapStateToProps = ({ notification }) => ({ text: notification.message });
const mapDispatchToProps = dispatch => {
  return {
    setNotificationData: data => dispatch(setNotificationData(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
