export default {
    pickUpDate: {
        'en': 'Pick-up date',
        'nl': 'Ophaal- en bezorgtijd',
    },
    deliveryDate: {
        'en': 'Delivery date',
        'nl': 'Bezorgmoment ',
    },
    selectDateAndTime: {
        'en': 'Select date & time',
        'nl': 'Selecteer een datum & tijd',
    },

}