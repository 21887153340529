import React from "react";

import { Button } from "./../Button";
import translation from "../../translations/Login";

import {
  setNotificationData,
  setApiToken
} from "../../redux/actions";

import { compose } from "redux";
import { connect } from "react-redux";
import {userCreateSession, userLoadData, validateEmail} from "../../utils/helpers";

import "css/components/auth/Login.css";
import { Link } from "react-router-dom";
import Api from "../../utils/api/Api";

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      valid: false
    };

    this.button = React.createRef();
    this.notification = props.notification;
    this.lang = localStorage.getItem("lang")
  }

  setEmail = (e) => {
    let email = e.target.value;

    this.setState({
      valid: validateEmail(email),
      email
    })
  };

  setPassword = (e) => {
    this.setState({
      password: e.target.value
    })
  };

  isValid = () => {
    return this.state.valid && 0 < this.state.password.length
  };

  login = async () => {
    Api.authorize(this.state.email, this.state.password)
      .then(async ({ data }) => {
        let token = data.token;

        await userCreateSession(token, data.refresh);
        await userLoadData(
          () => {
            this.props.onLogin(token);
          },
          () => {
            if (this.button.current) {
              this.button.current.enable();
            }
          }
        );
      }).catch(() => {
        this.props.setNotificationData({ status: true, message: translation.error[this.lang] });
        this.button.current.enable();

        setTimeout(() => {
          this.props.setNotificationData({ status: false, message: '' })
        }, 5000);
      });
  };

  render() {
    return (
      <div>
        <div className="user-login" style={{width: '100%'}}>
          <input
            id="auth-login-email-input"
            type="email"
            placeholder={translation.email[this.lang]}
            value={ this.state.email }
            onChange={ this.setEmail }
            required
          />
          <input
            id="auth-login-email-input"
            type="password"
            placeholder={translation.password[this.lang]}
            value={ this.state.password }
            onChange={ this.setPassword }
            required
          />
          <div className='actions-inline-container'>
            <Button
              id="auth-login-button"
              color="green"
              className={ this.isValid() ? 'checked' : '' }
              onClick={ this.login }
              disabled={ !this.isValid() }
              ref={ this.button }
            >
              { translation.button[this.lang] }
            </Button>
            <Link id="auth-login-reset-link" to={{
              pathname: '/' + this.lang + '/recover'
            }}>{ translation.recover[this.lang] }</Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ notification }) => ({
  notification
});

const mapDispatchToProps = dispatch => {
  return {
    setApiToken: data => dispatch(setApiToken(data)),
    setNotificationData: data => dispatch(setNotificationData(data)),
  };
};

export default compose(
  connect(
    mapDispatchToProps,
    mapStateToProps
  )
)(Login);