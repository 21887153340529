export default {
    selectService: {
        'en': 'Please select the service you prefer',
        'nl': 'Kies je service',
    },
    minOrderValue: {
        'en': 'Minimum order value €25. Free pickup and delivery.',
        'nl': 'Minimale orderwaarde €24,95. Gratis halen & brengen.',
    },
    dryCleaning: {
        'en': 'Drycleaning Service',
        'nl': 'Stomerij Service',
    },
    dryCleaningSub: {
        'en': 'Suits, blazers, trousers, curtains, duvet, coats etc. Price per item.',
        'nl': 'Pakken, jasjes, broeken, gordijnen, dekbedden, jassen etc. Prijs per item.',
    },
    washAndFold: {
        'en': 'Wash & Fold',
        'nl': 'Wassen & Vouwen',
    },
    washAndFoldSub: {
        'en': 'Clothing is washed, dried and folded (no ironing). Price per kg.',
        'nl': 'Kleding wassen, drogen en vouwen (niet gestreken). Prijs per kg.',
    },
    shirtAndService: {
        'en': 'Shirt Service',
        'nl': 'Overhemden Service',
    },
    shirtAndServiceSub: {
        'en': 'Washed & pressed and returned on hanger. Price per item € 2,95.',
        'nl': `Wassen & strijken en op hanger geleverd. Prijs per stuk € 2,95`,
    },
    shoeCleaning: {
        'en': 'Shoe Cleaning',
        'nl': 'Schoenen Reiniging',
    },
    shoeCleaningSub: {
        'en': 'Sneakers and suède shoes cleaned by hand. Price per pair from € 9,95',
        'nl': `Sneakers en suède schoenen met de hand schoongemaakt. Prijs per paar vanaf € 9,95`,
    },
    expertOrder: {
        'en': 'Expert Order',
        'nl': 'Expert Order',
    },
    expertOrderSub: {
        'en': 'Let our cleaning experts chose the right treatment for your garments. Price per item / kg',
        'nl': `Laat onze reiniging experts de juiste behandeling kiezen voor elk item. Prijs per item / kg`,
    },
    addNotes: {
        'en': 'Add order notes here',
        'nl': 'Voeg een notitie toe',
    },
    addNotesDesc: {
        'en': 'Ironing? Or any other additional requests, can be added here. e.g ketchup stain on white shirt',
        'nl': 'Ketchupvlek op wit overhemd, of andere informatie voor onze specialisten.',
    },
    optional: {
        'en': 'Optional',
        'nl': 'Optioneel',
    },
    hasBagTitle: {
        'en': 'Because this is your first order, we will send you a Dobbi laundry bag',
        'nl': 'Omdat dit je eerste order is sturen we je een Dobbi waszak',
    },
    hasBagFirstPoint: {
        'en': 'Delivered by mail, fits through your letter box',
        'nl': 'Komt met de post en past door je brievenbus',
    },
    hasBagSecondPoint: {
        'en': 'Delivery at the next working day',
        'nl': 'Volgende werkdag in huis',
    },
    hasBagThirdPoint: {
        'en': 'Handy deposit system',
        'nl': 'Handig statiegeld systeem',
    },
    hasBagButtonText: {
        'en': 'I already have a dobbi laundry bag',
        'nl': 'Ik heb al een dobbi waszak',
    }
}